import ican from '@cryptohub/ican';
import { ENVIRONMENT } from '@ping/configs';
import multicoin from 'multicoin-address-validator';

const CORE_CURRENCIES = new Set(['xcb', 'xce', 'xab', 'ctn', 'cts', 'ceur', 'cusd']);
const NETWORK_BASED_ON_ENV = new Map<typeof ENVIRONMENT, string>()
  .set('develop', 'both')
  .set('staging', 'both')
  .set('testnet', 'both')
  .set('production', 'prod');

// const LITECOIN_REGEX = {
//   LEGACY: /^[LM][a-km-zA-HJ-NP-Z1-9]{26,34}$/,
//   BECH32: /^ltc1[a-z0-9]{39,59}$/,
// };

interface IIsValidAddressInput {
  address: string;
  assetId: string;
}

/**
 * It checks if an address is valid based on the asset ID and network environment.
 * @param {IIsValidAddressInput} input - The input parameter is an object with the following
 * properties:
 * - address
 * - assetId
 */
export const isValidAddress = (input: IIsValidAddressInput) => {
  let output = false;
  const assetId = input.assetId.toLowerCase();

  if (CORE_CURRENCIES.has(assetId)) {
    output = ican.isValid(input.address);
  } else if (assetId === 'ltc') {
    // Custom validation for Litecoin addresses
    //ToDo remove validation for litecoin for release
    return true;
    // output = LITECOIN_REGEX.LEGACY.test(input.address) || LITECOIN_REGEX.BECH32.test(input.address);
  } else {
    output = multicoin.validate(input.address, assetId, NETWORK_BASED_ON_ENV.get(ENVIRONMENT));
  }

  // TODO: for testing 86bwhe0zg
  console.log('isValidAddress', output, input, ENVIRONMENT, NETWORK_BASED_ON_ENV.get(ENVIRONMENT));

  return output;
};
