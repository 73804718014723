import { t } from '@ping/helpers';
import { Select } from '@ping/uikit/SelectA11Y';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';

import style from './style.module.scss';
import { useGetNetworksWallet } from '@ping/api';

interface INetworkSelectProps extends ICustomizable {
  assetId?: string;
  selectedKey?: string;
  label?: string;
  isDisabled?: boolean;
  magnet?: IMagnet;
  onChange?(network: string): void;
}

export const NetworkSelect = (props: INetworkSelectProps) => {
  const [selectedNetworkKey, setSelectedNetworkKey] = useState<string>();
  const { data: networkWallet, isLoading: networkWalletLoading } = useGetNetworksWallet({ AssetId: props.assetId });

  useEffect(() => {
    setSelectedNetworkKey('');
  }, [props.assetId, networkWalletLoading]);

  const handleOnSelectionChange = (network: string) => {
    props.onChange?.(network);
    setSelectedNetworkKey(network);
  };

  const getNetworkDescription = network => {
    return network.split(/(?=[A-Z])/).join(' ');
  };

  return (
    <Select
      className={clsx(style['network-select'], props.className)}
      items={networkWallet || []}
      isLoading={false}
      selectedKey={selectedNetworkKey}
      isDisabled={props.isDisabled}
      magnet={props.magnet}
      label={props.label}
      onSelectionChange={handleOnSelectionChange}
      emptyState={t('No networks')}
      buttonContent={selectedNetworkKey ? getNetworkDescription(selectedNetworkKey) : t('Select a network')}
      topElement={
        <div>
          <p className={style['network-select__title']}>{t('Select network')}</p>
          <p className={style['network-select__subtitle']}>
            {t(
              'Confirm the network you choose to deposit matches the withdrawal network, otherwise assets will be lost'
            )}
          </p>
        </div>
      }
    >
      {network => (
        <Select.Item className={style['network-select__item']} key={network.name} aria-label={network.name}>
          <span className={style['network-select__name']}>{getNetworkDescription(network.name)}</span>
        </Select.Item>
      )}
    </Select>
  );
};

export default NetworkSelect;
