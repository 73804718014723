import InfoIcon from '@ping/assets/Icon/circle-error.svg';
import { t } from '@ping/helpers';
import { enhanceAssetsNoticeStore } from '@ping/stores/enhance-assets-notice.store';
import CloseIcon from '@ping/assets/Icon/close.svg';

import { ButtonA11Y } from '@ping/uikit';

import style from './style.module.scss';

interface IEnhanceAssetsNotice {
  handleClickWithdrawFunds: () => void;
}

export const EnhanceAssetsNotice = ({ handleClickWithdrawFunds }: IEnhanceAssetsNotice) => {
  const { setEnhanceAssetsNotice } = enhanceAssetsNoticeStore;

  return (
    <div className={style['enhance-assets-notice']}>
      <div className={style['enhance-assets-notice__header']}>
        <div>
          <InfoIcon />
          <span>{t('Enhance assets protection')}</span>
        </div>
        <CloseIcon
          onClick={() => {
            setEnhanceAssetsNotice(false);
          }}
          className={style['enhance-assets-notice__header-cancel-icon']}
        />
      </div>
      <div className={style['enhance-assets-notice__description']}>
        {t(
          'We have observed that your current portfolio holds a considerable balance. While we ensure strict security measures for funds on our platform, we always encourage taking additional precautions. Consider withdrawing some of these funds to a personal, preferably a cold wallet, for enhanced security.'
        )}
      </div>
      <div className={style['enhance-assets-notice__footer']}>
        <ButtonA11Y
          onPress={() => {
            setEnhanceAssetsNotice(false);
          }}
          className={style['enhance-assets-notice__footer-button']}
        >
          {t('Don’t show this again')}
        </ButtonA11Y>
        <ButtonA11Y onPress={handleClickWithdrawFunds} className={style['enhance-assets-notice__footer-button']}>
          {t('Withdraw funds')}
        </ButtonA11Y>
      </div>
    </div>
  );
};
