import CircleWarningIcon from '@ping/assets/Icon/circle-warning.svg';
import { t } from '@ping/helpers';
import { Text } from '@ping/uikit';
import Link from 'next/link';

import style from './style.module.scss';

interface ILimitWarningProps {
  usersCurrentLimit: number;
  perTransactionLimit?: number;
  totalMonthlyLimit?: number;
  totalYearlyLimit?: number;
  userSelectedCurrency?: string;
  exceedingCurrentLimit?: boolean;
}

const limitMessages = (
  usersCurrentLimit: number,
  perTransactionLimit?: number,
  totalMonthlyLimit?: number,
  totalYearlyLimit?: number,
  userSelectedCurrency?: string,
  exceedingCurrentLimit?: boolean
) => {
  switch (true) {
    case usersCurrentLimit === perTransactionLimit:
      return {
        title: t('Exceeding max transaction limit'),
        info: t(
          `Please, note that the transaction you are about to make exceeds the ${perTransactionLimit} ${userSelectedCurrency} per transaction maximum limit.`
        ),
      };
    case usersCurrentLimit === totalYearlyLimit:
      return {
        title: t('Exceeding yearly withdraw limit'),
        info: t(
          `Please, note that the transaction you are about to make exceeds the ${totalYearlyLimit} ${userSelectedCurrency} calendar year limit.`
        ),
      };
    case usersCurrentLimit === totalMonthlyLimit || exceedingCurrentLimit:
      return {
        title: t('Exceeding monthly withdraw limit'),
        info: t(
          `Please, note that the transaction you are about to make exceeds the ${totalMonthlyLimit} ${userSelectedCurrency} calendar month withdraw limit.`
        ),
      };
    default:
      return {
        title: '',
        info: '',
      };
  }
};

export const LimitsWarningMessage = ({
  usersCurrentLimit,
  perTransactionLimit,
  totalMonthlyLimit,
  totalYearlyLimit,
  userSelectedCurrency,
  exceedingCurrentLimit,
}: ILimitWarningProps) => {
  const { title, info } = limitMessages(
    usersCurrentLimit,
    perTransactionLimit,
    totalMonthlyLimit,
    totalYearlyLimit,
    userSelectedCurrency,
    exceedingCurrentLimit
  );

  return (
    <section className={style['limits-warning']}>
      <span className={style['limits-warning__icon']}>
        <CircleWarningIcon />
      </span>

      <div>
        {title && (
          <Text body='semi-bold' className={style['limits-warning__heading']}>
            {title}
          </Text>
        )}
        {info && <Text body='regular'>{info}</Text>}
        <div className={style['limits-warning__link']}>
          <Link href='/user/tiers'>{t('Learn more about tiers')}</Link>
        </div>
      </div>
    </section>
  );
};
