import { useState } from 'react';
import { useTransition } from 'transition-hook';

import DriverLicenseIcon from '@ping/assets/Icon/driver-license.svg';
import PassportIcon from '@ping/assets/Icon/passport.svg';
import ResidencyPermitIcon from '@ping/assets/Icon/residency-permit.svg';
import { t } from '@ping/helpers';
import { Modal } from '@ping/uikit';
import { ButtonA11Y } from '@ping/uikit/ButtonA11Y';
import { DateTime } from '@ping/utils';

import { Tag } from '../Tag';
import GoBackIcon from './go-back.svg';
import NextStepIcon from './next-step.svg';
import StepOneGif from './step-one.gif';
import StepTwoGif from './step-two.gif';
import style from './style.module.scss';

const ONE = 1 as const;
const TWO = 2 as const;

export const KycWatchTutorialModalTrigger = () => {
  const [step, setStep] = useState<number>(ONE);
  const stepOneTransition = useTransition(step === ONE, DateTime.milliseconds(360));
  const stepTwoTransition = useTransition(step === TWO, DateTime.milliseconds(360));

  const handleOnOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setStep(ONE);
    }
  };

  return (
    <Modal.Root onOpenChange={handleOnOpenChange}>
      <Modal.Trigger className={style['kyc-watch-tutorial-trigger']}>{t('Watch tutorial')}</Modal.Trigger>

      <Modal.Portal className={style['kyc-watch-tutorial-modal']}>
        <Modal.Header>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Content>
          <div className={style['kyc-watch-tutorial-modal__illustration']}>
            {stepOneTransition.shouldMount && (
              <img
                className={style['kyc-watch-tutorial-modal__gif']}
                data-transition={stepOneTransition.stage}
                data-step={ONE}
                src={StepOneGif.src}
                alt={t('Digitize a government-issued ID document - animated gif illustration')}
              />
            )}
            {stepTwoTransition.shouldMount && (
              <img
                className={style['kyc-watch-tutorial-modal__gif']}
                data-transition={stepTwoTransition.stage}
                data-step={TWO}
                src={StepTwoGif.src}
                alt={t('Digitize your email - animated gif illustration')}
              />
            )}
          </div>

          <section
            className={style['kyc-watch-tutorial-modal__instruction']}
            aria-hidden={!stepOneTransition.shouldMount}
            data-transition={stepOneTransition.stage}
            data-step={ONE}
          >
            <div className={style['kyc-watch-tutorial-modal__step-indicators']}>
              <span className={style['kyc-watch-tutorial-modal__step-indicator']} aria-current={step === ONE} />
              <span className={style['kyc-watch-tutorial-modal__step-indicator']} aria-current={step === TWO} />
            </div>

            <Modal.Title>{t('Digitize a government-issued ID document')}</Modal.Title>
            <p className={style['kyc-watch-tutorial-modal__description']}>
              <span>{t('Buy a voucher to digitize a government-issued')}</span>&nbsp;
              <span className={style['kyc-watch-tutorial-modal__highlight']}>{t('ID document')}</span>&nbsp;
              <span>{t('of your choice. We support the following document types:')}</span>
            </p>

            <Tag.Group className={style['document']}>
              <Tag>
                <span className={style['document__icon']}>
                  <PassportIcon />
                </span>
                <span className={style['document__title']}>{t('National ID card')}</span>
              </Tag>
              <Tag>
                <span className={style['document__icon']}>
                  <DriverLicenseIcon />
                </span>
                <span className={style['document__title']}>{t('Driver’s license')}</span>
              </Tag>
              <Tag>
                <span className={style['document__icon']}>
                  <ResidencyPermitIcon />
                </span>
                <span className={style['document__title']}>{t('Passport')}</span>
              </Tag>
            </Tag.Group>

            <ButtonA11Y className={style['step-button']} aria-details='next' onPress={() => setStep(TWO)}>
              <span className={style['step-button__text']}>{t('Next step')}</span>
              <NextStepIcon className={style['step-button__icon']} />
            </ButtonA11Y>
          </section>

          <section
            className={style['kyc-watch-tutorial-modal__instruction']}
            aria-hidden={!stepTwoTransition.shouldMount}
            data-transition={stepTwoTransition.stage}
            data-step={TWO}
          >
            <div className={style['kyc-watch-tutorial-modal__step-indicators']}>
              <span className={style['kyc-watch-tutorial-modal__step-indicator']} aria-current={step === ONE} />
              <span className={style['kyc-watch-tutorial-modal__step-indicator']} aria-current={step === TWO} />
            </div>

            <Modal.Title>{t('Digitize your email')}</Modal.Title>
            <p className={style['kyc-watch-tutorial-modal__description']}>
              <span>{t('Under contact information, buy a voucher to digitize your')}</span>&nbsp;
              <span className={style['kyc-watch-tutorial-modal__highlight']}>{t('email address')}</span>
            </p>

            <ButtonA11Y className={style['step-button']} aria-details='back' onPress={() => setStep(ONE)}>
              <span className={style['step-button__text']}>{t('Go back')}</span>
              <GoBackIcon className={style['step-button__icon']} />
            </ButtonA11Y>
          </section>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
