import { t } from '@ping/helpers';
import style from './style.module.scss';

export const KycCardContactUsSection = () => {
  return (
    <div className={style['kyc-contact-section']}>
      <h6 className={style['kyc-pending-right-side__title']}>{t('Contact us')}</h6>
      <p className={style['kyc-contact-section__description']}>
        {t(
          `Have questions, concerns or require further assistance? Please, don't hesitate to reach out to our support team, and we'll get back to you promptly.`
        )}
      </p>
      <span className={style['kyc-contact-section__email']}>compliance@ping.exchange</span>
    </div>
  );
};
