import { useDepositWallet, useGetAllAsset, useGetPortfolio } from '@ping/api';
import CopyIcon from '@ping/assets/Icon/copy.svg';
import { t } from '@ping/helpers';
import { useDesktopDevice, useSelectedCurrency } from '@ping/hooks';
import { AssetSelect, CoinItem, Copyable, Divider, Text, Compact } from '@ping/uikit';
import { format, DateTime } from '@ping/utils';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';

import style from './style.module.scss';

import type { PortfolioItemResponse } from '@ping/api';
import type { ICoinValue } from '@ping/uikit';
import { DEFAULT_MARKET_QUOTE } from '@ping/configs';
import NetworkSelect from '@ping/components/CryptoTransaction/NetworkSelect';

type IDepositProps = {
  assetId?: string;
};

interface IAsset {
  address: string;
  id: string;
  amount: number;
  balance: number;
  confirmationCount: number | undefined;
}

const INITIAL_ASSET: IAsset = {
  address: '',
  id: '',
  amount: 0,
  balance: 0,
  confirmationCount: undefined,
};

const ReceiveTransaction = (props: IDepositProps) => {
  const selectedCurrency = useSelectedCurrency();
  const [assetInfo, setAssetInfo] = useState<IAsset>({ ...INITIAL_ASSET, id: props.assetId?.toUpperCase() });
  const [qrCodeUrlLink, setQrCodeUrlLink] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [expanded, setExpanded] = useState(false);

  const handleCompactClick = () => {
    setExpanded(!expanded);
  };

  const { mutate: mutateRequestFundsDepositWallet, isLoading: isLoadingRequestFundsDepositWallet } = useDepositWallet();

  const { data: portfolio, isLoading: isPortfolioLoading } = useGetPortfolio({
    query: {
      staleTime: DateTime.minutes(5),
      select: ({ portfolioItems }) => {
        const assetsMap = portfolioItems?.reduce(
          (map, item) => map.set(item.assetId.toUpperCase(), item),
          new Map<string, PortfolioItemResponse>()
        );

        return { assetsMap };
      },
    },
  });

  const { data: assets, isLoading: isAssetsLoading } = useGetAllAsset({
    query: {
      staleTime: DateTime.days(1),
      select: ({ data: allAssets }) => {
        const options = allAssets.reduce<ICoinValue<string>[]>((options, asset) => {
          if (asset.can_deposit) {
            options.push({
              value: asset.id.toUpperCase(),
              view: <CoinItem className='cursor-pointer' coinName={asset.id.toUpperCase()} />,
            });
          }

          return options;
        }, []);

        const confirmationCountsMap = allAssets?.reduce(
          (map, item) => map.set(item.id.toUpperCase(), item.confirmation_count),
          new Map<string, number | undefined>()
        );

        return { options, confirmationCountsMap };
      },
    },
  });

  const isDesktop = useDesktopDevice();

  useEffect(() => handleOnSelectAsset(assetInfo.id), [isPortfolioLoading, isAssetsLoading]);

  const handleOnSelectAsset = (assetId: string) => {
    if (isPortfolioLoading || isAssetsLoading || !assetId?.length) return;
    const { amount, balance } = portfolio.assetsMap.get(assetId.toUpperCase()) || {};

    setAssetInfo({
      address: INITIAL_ASSET.address,
      id: assetId.toUpperCase(),
      amount: amount || INITIAL_ASSET.amount,
      balance: balance || INITIAL_ASSET.balance,
      confirmationCount: INITIAL_ASSET.confirmationCount,
    });
  };

  const handleNetworkChange = network => {
    setSelectedNetwork(network);
    mutateRequestFundsDepositWallet(
      { data: { assetId: assetInfo.id.toLowerCase(), paymentSystem: network } },
      {
        onSuccess: deposit => {
          const confirmationCount = assets.confirmationCountsMap.get(assetInfo.id.toUpperCase());

          setAssetInfo({
            address: deposit.address,
            id: assetInfo.id.toUpperCase(),
            amount: assetInfo.amount || INITIAL_ASSET.amount,
            balance: assetInfo.balance || INITIAL_ASSET.balance,
            confirmationCount: confirmationCount || INITIAL_ASSET.confirmationCount,
          });
          setExpanded(false);
        },
        onError: () => {
          setAssetInfo(INITIAL_ASSET);
        },
      }
    );
  };

  useEffect(() => {
    setQrCodeUrlLink(assetInfo.address);
    if (assetInfo.id === 'BTC') {
      setQrCodeUrlLink(`bitcoin:${assetInfo.address}`);
    }
    if (assetInfo.id === 'CTN') {
      setQrCodeUrlLink(`payto://xcb/${assetInfo.address}?amount=${assetInfo.id}:`);
    }
    if (assetInfo.id === 'XCB') {
      setQrCodeUrlLink(`payto://xcb/${assetInfo.address}`);
    }
    if (assetInfo.id === 'ETH' || assetInfo.id === DEFAULT_MARKET_QUOTE) {
      setQrCodeUrlLink(`ethereum:${assetInfo.address}`);
    }
    if (assetInfo.id === 'LTC') {
      setQrCodeUrlLink(`litecoin:${assetInfo.address}`);
    }
  }, [assetInfo]);

  return (
    <div
      className={style['receive-transaction']}
      data-deposit-address-loading={isLoadingRequestFundsDepositWallet}
      data-is-asset-selected={Boolean(assetInfo.id.length)}
      data-is-network-selected={Boolean(selectedNetwork.length)}
    >
      <Divider className={style['receive-transaction__divider']} />
      <div className={style['receive-transaction__asset']}>
        <div>
          <Text body='regular' className={style['receive-transaction__coin-select-label']}>
            {t('Currency')}
          </Text>

          <AssetSelect onChange={handleOnSelectAsset} assetId={assetInfo.id} filterFn={asset => asset.can_deposit} />
        </div>
        <Text caption='regular' className={style['receive-transaction__asset-balance']}>
          {`${assetInfo.id} Balance: ${format.crypto(assetInfo.amount, assetInfo.id)}`}
          {Boolean(assetInfo.amount) && ` (${format.fiat(assetInfo.balance, selectedCurrency)})`}
        </Text>
      </div>

      <section id={style['networks-dropdown']}>
        <Text body='regular' className={style['receive-transaction__coin-select-label']}>
          {t('Network')}
        </Text>

        <NetworkSelect onChange={handleNetworkChange} assetId={assetInfo.id.toLowerCase()} />
      </section>

      <section className={style['receive-transaction__address-section']}>
        <div className={style['receive-transaction__qr-code-wrapper']}>
          <QRCodeSVG
            className={style['receive-transaction__qr-code']}
            size={136}
            value={decodeURIComponent(qrCodeUrlLink)}
            level='M'
          />
        </div>

        <div className={style['receive-transaction__address-details-wrapper']}>
          <div className={style['receive-transaction__address-details']}>
            <Text className={style['receive-transaction__address-title']} body='semi-bold'>
              {t(`${assetInfo.id} address`)}
            </Text>

            <Copyable
              className={style['receive-transaction__address-copy']}
              title={t('Wallet address')}
              text={assetInfo.address}
            >
              <CopyIcon />
            </Copyable>

            <Text className={style['receive-transaction__address-text']} body='regular'>
              <Compact
                text={assetInfo.address}
                middle
                visibleCharacterCount={15}
                isExpanded={expanded}
                onClick={handleCompactClick}
              />
            </Text>
          </div>
          {!isDesktop && (
            <div className={style['receive-transaction__button-block']}>
              <a className={style['receive-transaction__button-block-button']} href={qrCodeUrlLink}>
                {t('Deposit via wallet')}
              </a>
              <Text caption='regular' grayColor>
                {t('The deposit button allows you to quickly deposit assets into your Ping account.')}
              </Text>
            </div>
          )}
        </div>
      </section>

      <section className={style['receive-transaction__info-section']}>
        <Text className={style['receive-transaction__info-text']} caption='regular'>
          {t('Expected arrival')}
        </Text>
        <Text className={style['receive-transaction__info-text']} caption='regular'>
          {t(`${assetInfo.confirmationCount} network confirmation`)}
        </Text>
      </section>

      <section className={style['receive-transaction__info-section']}>
        <Text className={style['receive-transaction__info-text']} caption='regular'>
          {t('Minimum deposit')}
        </Text>
        <Text className={style['receive-transaction__info-text']} caption='regular'>
          {/* TODO: Minimum deposit */}
          {`0.00001 ${assetInfo.id}`}
        </Text>
      </section>

      <section className={style['receive-transaction__footer-section']}>
        <Text className={style['receive-transaction__footer-text']} caption='regular'>
          {t(`Please remember to send only ${assetInfo.id} to this deposit address`)}
        </Text>
      </section>
    </div>
  );
};

export default ReceiveTransaction;
