import { useState } from 'react';
import { t } from '@ping/helpers';
import { ToggleButton } from '@ping/uikit';
import SendTransaction from './SendTransaction';
import ReceiveTransaction from './ReceiveTransaction';

import style from './style.module.scss';
import { VerifyAddressWithSignedFormResult } from '@ping/api';

export enum SEND_RECEIVE_TABS {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
}

interface ICryptoTransactionProps {
  action?: SEND_RECEIVE_TABS;
  assetId?: string;
  onCancel?: () => void;
  onLTCVerify?: (data: VerifyAddressWithSignedFormResult) => void;
}

export const CryptoTransaction = (props: ICryptoTransactionProps) => {
  const [action, setAction] = useState<SEND_RECEIVE_TABS | undefined>(props.action);
  const [hideToggle, setHideToggle] = useState<string | boolean>(false);

  const isSendVisible = action === SEND_RECEIVE_TABS.SEND;
  const isReceiveVisible = action === SEND_RECEIVE_TABS.RECEIVE;

  return (
    <div className={style['crypto-transaction']} role='region' aria-label={t('Crypto Transaction')}>
      {!hideToggle && (
        <ToggleButton.Root
          className={style['crypto-transaction__toggle']}
          defaultValue={action}
          onChange={(val: SEND_RECEIVE_TABS) => setAction(val)}
          aria-label={t('Transaction Type Selection')}
        >
          <ToggleButton.Choice value={SEND_RECEIVE_TABS.SEND} aria-label={t('Send Crypto')}>
            {t('Send')}
          </ToggleButton.Choice>
          <ToggleButton.Choice value={SEND_RECEIVE_TABS.RECEIVE} aria-label={t('Receive Crypto')}>
            {t('Receive')}
          </ToggleButton.Choice>
        </ToggleButton.Root>
      )}

      {isSendVisible && (
        <div className={style['crypto-transaction__content']} role='tabpanel' aria-label={t('Send Transaction Panel')}>
          <SendTransaction
            assetId={props.assetId}
            onCancel={props.onCancel}
            onChangeView={setHideToggle}
            onLTCVerify={props.onLTCVerify}
          />
        </div>
      )}

      {isReceiveVisible && (
        <div
          className={style['crypto-transaction__content']}
          role='tabpanel'
          aria-label={t('Receive Transaction Panel')}
        >
          <ReceiveTransaction assetId={props.assetId} />
        </div>
      )}
    </div>
  );
};

export default CryptoTransaction;
