import CheckIcon from './CheckIcon.svg';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { t } from '@ping/helpers';
import { useRemoteModal } from '@ping/hooks';
import { Button, Modal } from '@ping/uikit';

import style from './style.module.scss';

export const AddressVerificationSuccessfulModal = () => {
  const modal = useRemoteModal(modalKeys.ADDRESS_VERIFICATION_SUCCESSFUL);

  return (
    <Modal.Root {...modal.props}>
      <Modal.Portal className={style['verification-modal']}>
        <Modal.Content className={style['verification-modal__content']}>
          <CheckIcon className={style['verification-modal__icon']} />
          <Modal.Title className={style['verification-modal__title']}>
            {t('Your Wallet is Ready for Withdrawals')}
          </Modal.Title>
          <Modal.Subtitle className={style['verification-modal__description']}>
            {t(
              'Your external Bitcoin wallet address has been successfully linked with Ping. You can now initiate withdrawals to this address.'
            )}
          </Modal.Subtitle>
        </Modal.Content>
        <Button
          type='primary'
          design='general'
          size='large'
          className={style['verification-modal__button']}
          onClick={() => {
            modal.state.close();
          }}
        >
          {t('START A WITHDRAWAL')}
        </Button>
      </Modal.Portal>
    </Modal.Root>
  );
};
