import { createAddressBook, updateAddressBook, useGetNetworksWallet } from '@ping/api';
import { t } from '@ping/helpers';
import { Button, Text, Toast } from '@ping/uikit';
import { useEffect, useRef, useState } from 'react';
import { validateAddress } from './validation.helper';
import clsx from 'clsx';
import NetworkSelect from '@ping/components/CryptoTransaction/NetworkSelect';

import style from './style.module.scss';

type IContactCryptoFields = {
  accountName?: string;
  address: string;
  network: string;
};

interface IEditModeProps {
  mode: 'edit';
  addressID: string;
  defaultValues: IContactCryptoFields;
}

interface ICreateModeProps {
  mode?: 'create';
  addressID?: never;
  defaultValues?: IContactCryptoFields;
}

type IContactCryptoProps = {
  finishCallback: (result: IContactCryptoFields) => void;
} & (ICreateModeProps | IEditModeProps);

interface IContactCryptoFormErrors {
  accountName?: string;
  address?: string;
  network?: string;
}

export const ContactCrypto = (props: IContactCryptoProps) => {
  const { finishCallback, mode = 'create' } = props;
  const addressID = mode === 'edit' ? props?.addressID : null;
  const defaultValues = props?.defaultValues;
  const [address, setAddress] = useState(defaultValues?.address || '');
  const [accountName, setAccountName] = useState(defaultValues?.accountName || '');
  const [network, setNetwork] = useState(defaultValues?.network || '');
  const [networkAssetsList, setNetworkAssetsList] = useState([]);
  const isFormSubmitted = useRef<boolean>(false);
  const [formErrors, setFormErrors] = useState<IContactCryptoFormErrors>({});

  const hasError = !address || !network || !accountName;
  const isEditMode = mode === 'edit';

  const { data: networkWallet } = useGetNetworksWallet();

  useEffect(() => {
    setNetwork(defaultValues?.network || '');
    setAddress(defaultValues?.address || '');
    setNetworkAssetsList(
      networkWallet
        ?.filter(item => item.name === defaultValues?.network)
        .map(item => item.assets)
        .flat() || []
    );
  }, [defaultValues, networkWallet]);

  useEffect(() => {
    if (isFormSubmitted.current) {
      handleSetErrors();
    }
  }, [address, accountName, network]);

  const handleSetErrors = () => {
    const errors: IContactCryptoFormErrors = {
      address: validateAddress(address, networkAssetsList),
      network: !network ? t('Please select the network') : undefined,
      accountName: !accountName.trim() ? t('Please enter the identifier') : undefined,
    };
    setFormErrors(errors);
  };

  const onNetworkSelect = (network: string) => {
    setNetwork(network);
    setNetworkAssetsList(
      networkWallet
        ?.filter(item => item.name === network)
        .map(item => item.assets)
        .flat() || []
    );
  };

  const onContactCryptoSuccess = () => {
    finishCallback({ address, network, accountName });
    isFormSubmitted.current = false;
    setAddress('');
    setAccountName('');
    setNetwork('');
    setNetworkAssetsList([]);
    Toast.success({ title: 'The address has been successfully saved' });
  };

  const onContactCryptoFail = error => {
    error?.response?.data?.errors?.forEach(errorItem => {
      Toast.error({ title: errorItem?.message });
    });
  };

  const handleContactCryptoClick = event => {
    event.preventDefault();
    event.stopPropagation();

    isFormSubmitted.current = true;
    if (hasError || formErrors.address || formErrors.accountName) {
      handleSetErrors();
    } else {
      if (isEditMode) {
        updateAddressBook(addressID, { label: accountName }).then(onContactCryptoSuccess).catch(onContactCryptoFail);
      } else {
        createAddressBook({ walletAddress: address, label: accountName })
          .then(onContactCryptoSuccess)
          .catch(onContactCryptoFail);
      }
    }
  };
  const handleAddressFocusLost = () => {
    handleSetErrors();
  };

  return (
    <form className={style['contactCrypto']} onSubmit={handleContactCryptoClick}>
      <div className={style['contactCrypto__block']}>
        <NetworkSelect label={t('Network')} onChange={e => onNetworkSelect(e)} selectedKey={network} />
        {formErrors.network && (
          <Text body='regular' className={style['contactCrypto__error']}>
            {formErrors.network}
          </Text>
        )}
      </div>
      <div className={style['contactCrypto__block']}>
        <Text body='regular' className={style['contactCrypto__text']}>
          {t('Identifier')}
        </Text>
        <input
          type='text'
          placeholder={t('Address nickname')}
          value={accountName}
          onChange={e => setAccountName(e.target.value)}
          className={clsx(style['contactCrypto__input'], `${formErrors.accountName ? 'has-input-error' : ''}`)}
        />
        {formErrors.accountName && (
          <Text body='regular' className={style['contactCrypto__error']}>
            {formErrors.accountName}
          </Text>
        )}
      </div>
      <div className={style['contactCrypto__block']}>
        <Text body='regular' className={style['contactCrypto__text']}>
          {t('Beneficiary address')}
        </Text>
        <input
          type='text'
          placeholder={t('Beneficiary address')}
          value={address}
          onChange={e => setAddress(e.target.value)}
          className={clsx(style['contactCrypto__input'], `${formErrors.address ? 'has-input-error' : ''}`)}
          onBlur={handleAddressFocusLost}
        />
        {formErrors.address && (
          <Text body='regular' className={style['contactCrypto__error']}>
            {formErrors.address}
          </Text>
        )}
      </div>

      <Button type='secondary' design='general' size='extra-large' uppercase htmlType='submit'>
        {t('Save')}
      </Button>
    </form>
  );
};
