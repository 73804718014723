import { t } from '@ping/helpers';
import { isValidAddress } from '@ping/vendors';

export const validateAddress = (address: string, networkAssetsList: Array<string>): string => {
  if (!address) {
    return t('Please enter beneficiary address');
  }
  const isValid = networkAssetsList.some(networkAssetId => isValidAddress({ address, assetId: networkAssetId }));
  return isValid ? '' : t('Invalid address');
};
