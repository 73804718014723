import WarningIcon from './WarningIcon.svg';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { t } from '@ping/helpers';
import { useRemoteModal } from '@ping/hooks';
import { Modal } from '@ping/uikit';

import style from './style.module.scss';

export const AddressVerificationFailedModal = () => {
  const modal = useRemoteModal(modalKeys.ADDRESS_VERIFICATION_FAILED);

  return (
    <Modal.Root {...modal.props}>
      <Modal.Portal className={style['verification-modal']}>
        <Modal.Content className={style['verification-modal__content']}>
          <WarningIcon className={style['verification-modal__icon']} />
          <Modal.Title className={style['verification-modal__title']}>
            {t('Complete verification to continue')}
          </Modal.Title>
          <Modal.Subtitle className={style['verification-modal__description']}>
            {t(
              'In order to continue go to your CorePass app and complete the proof of ownership verification. Once done, return here to continue.'
            )}
          </Modal.Subtitle>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
