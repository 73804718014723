import { type NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { getGetPortfolioQueryKey, useGetAllAsset, useGetPortfolio, VerifyAddressWithSignedFormResult } from '@ping/api';
import { CryptoTransaction } from '@ping/components';
import { SEND_RECEIVE_TABS } from '@ping/components/CryptoTransaction';
import { FORM_B_STEPS } from '@ping/constants';
import { t } from '@ping/helpers';
import { useProfile, useSelectedCurrency } from '@ping/hooks';
import { queryClient } from '@ping/providers/ReactQueryProvider';
import {
  canShowEnhanceAssetsNoticeSelector,
  useEnhanceAssetsNoticeStore,
} from '@ping/stores/enhance-assets-notice.store';
import {
  claimRewardsModalRemoteStateSelector,
  receiveUserDataModalRemoteStateSelector,
  useModalStore,
} from '@ping/stores/modal.store';
import {
  complianceStepSelector,
  isUserActivityStatusActiveSelector,
  isUserActivityStatusBannedSelector,
  isUserActivityStatusFrozenSelector,
  isUserActivityStatusWaitingForReviewSelector,
  isUserAtEntityTypeDeclarationStepSelector,
  isUserAtFormAStepSelector,
  isUserAtKycRequestStepSelector,
  isUserAtNoDataStepSelector,
  isUserVerifiedSelector,
  useUserInformationStore,
} from '@ping/stores/userInformation.store';
import { ActionTooltip, Button, CoinIcon, PageSpinner, Popup, Table, Text, Toast } from '@ping/uikit';
import { format, roundPercentage, DateTime } from '@ping/utils';
import { KycNotificationStatus, NotificationEvents, useNotificationWS } from '@ping/websockets';
import { UserLayout } from '@user/components';
import NoAssetView from '@user/portfolio/components/NoAssetView';

import {
  BalanceSummaryModal,
  EnhanceAssetsNotice,
  FormACard,
  FormBCard,
  KycCard,
  KycCardBanned,
  KycCardPending,
  TransferOnProcessingModal,
  AddressVerificationFailedModal,
  AddressVerificationSuccessfulModal,
} from './components';
import { FormLTC } from './components/FormLTC';

import style from './style.module.scss';
import Link from 'next/link';

export enum ModalType {
  Convert = 'convert',
  SendReceive = 'send_receive',
  None = 'none',
}

type PopupTabs = SEND_RECEIVE_TABS;

const Portfolio: NextPage = () => {
  const { data: portfolio, isFetching: isPortfolioLoading } = useGetPortfolio();
  const selectedCurrency = useSelectedCurrency();
  const { isLoading: isProfileLoading } = useProfile();
  const assetScales = useGetAllAsset({
    query: {
      staleTime: DateTime.days(1),
      select: ({ data: allAssets }) => {
        return allAssets.reduce((map, asset) => map.set(asset.id, asset.scale), new Map<string, number>());
      },
    },
  });

  const [currentAssetId, setCurrentAssetId] = useState('');
  const [modalType, setModalType] = useState<ModalType>(ModalType.None);
  const [showEnhanceAssetsNotice, setShowEnhanceAssetsNotice] = useState(false);

  const [popupActiveTab, setPopupActiveTab] = useState<PopupTabs>(SEND_RECEIVE_TABS.SEND);

  const receiveUserDataModalRemoteState = useModalStore(receiveUserDataModalRemoteStateSelector);
  const claimRewardsModalRemoteState = useModalStore(claimRewardsModalRemoteStateSelector);
  const canShowEnhanceAssetsNotice = useEnhanceAssetsNoticeStore(canShowEnhanceAssetsNoticeSelector);

  const isUserVerified = useUserInformationStore(isUserVerifiedSelector);
  const isUserActivityStatusActive = useUserInformationStore(isUserActivityStatusActiveSelector);
  const isUserActivityStatusWaitingForReview = useUserInformationStore(isUserActivityStatusWaitingForReviewSelector);
  const isUserActivityStatusBanned = useUserInformationStore(isUserActivityStatusBannedSelector);
  const isUserActivityStatusFrozen = useUserInformationStore(isUserActivityStatusFrozenSelector);

  const complianceStep = useUserInformationStore(complianceStepSelector);

  const isUserAtNoDataStep = useUserInformationStore(isUserAtNoDataStepSelector);
  const isUserAtEntityTypeDeclarationStep = useUserInformationStore(isUserAtEntityTypeDeclarationStepSelector);
  const isUserAtKycRequestStep = useUserInformationStore(isUserAtKycRequestStepSelector);
  const isUserAtFormAStep = useUserInformationStore(isUserAtFormAStepSelector);
  const [showLTCForm, setShowLTCForm] = useState(false);
  const [verifyLTCWalletData, setVerifyLTCWalletData] = useState<VerifyAddressWithSignedFormResult>();

  const { notificationData, notificationCallBack } = useNotificationWS();

  notificationCallBack?.(NotificationEvents.OnKycStatusChanged);

  const router = useRouter();
  useEffect(() => {
    const { modal, tab } = router.query;
    if (modal === 'send_receive' && tab === 'receive') {
      setModalType(ModalType.SendReceive);
      setPopupActiveTab(SEND_RECEIVE_TABS.RECEIVE);
    }

    if (modal === 'send_receive' && tab === 'send') {
      setModalType(ModalType.SendReceive);
      setPopupActiveTab(SEND_RECEIVE_TABS.SEND);
    }
  }, [router.query]);

  const getDropDownList = assetId => {
    return [
      {
        title: t('Send'),
        onClick: () => {
          setModalType(ModalType.SendReceive);
          setCurrentAssetId(assetId);
          setPopupActiveTab(SEND_RECEIVE_TABS.SEND);
        },
      },
      {
        title: t('Receive'),
        onClick: () => {
          setModalType(ModalType.SendReceive);
          setCurrentAssetId(assetId);
          setPopupActiveTab(SEND_RECEIVE_TABS.RECEIVE);
        },
      },
      // { // TODO: temporarily disabled (https://app.clickup.com/t/86bx7n3a4)
      //   title: t('Convert'),
      //   onClick: () => {
      //     setModalType(ModalType.Convert);
      //     setCurrentAssetId(assetId);
      //   },
      // },
    ];
  };

  const tableColumns = [
    {
      Header: t('Currency'),
      accessor: 'assetId',
      Cell: ({ value }: { value: string }) => (
        <span>
          <CoinIcon name={value.toUpperCase()} />
          {value.toUpperCase()}
        </span>
      ),
      sticky: 'left',
    },
    {
      Header: t('Amount'),
      accessor: originalRow => ({ amount: originalRow.amount, assetId: originalRow.assetId }),
      Cell: ({ value }: { value: any }) => (
        <span>
          {format.number(value.amount.toFixed(assetScales.data?.get(value.assetId)))}&nbsp;
          <Text badge cadetBlueColor uppercase>
            {value.assetId}
          </Text>
        </span>
      ),
    },
    {
      Header: <span>{t(`Amount (%s)`, selectedCurrency)}</span>,
      accessor: 'balance',
      Cell: ({ value }: { value: string }) => (
        <span>
          {format.number(Number(value).toFixed(2))}&nbsp;
          <Text badge cadetBlueColor>
            {selectedCurrency}
          </Text>
        </span>
      ),
    },
    {
      Header: t('Percentage Of Total'),
      accessor: 'percentageOfTotal',
      Cell: ({ value }: { value: string }) => (
        <span aria-details={format.exponential(value) + '%'} title={format.exponential(value) + '%'}>
          ≈ {roundPercentage(value)}%
        </span>
      ),
    },
    {
      Header: '',
      id: 'action',
      accessor: 'assetId',
      columnTextAlignment: 'end',
      Cell: ({ value }: { value: string }) => <ActionTooltip dropDownList={getDropDownList(value)} />,
    },
  ];

  const handleSendReceiveClose = () => {
    setModalType(ModalType.None);
    setCurrentAssetId('');
  };

  useEffect(() => {
    // The user's status before Form A is always WaitingForData and will change after the user has signed Form A.
    // The only exception is when the user is under the legal age, which the status will change to Frozen in this case (even though form A is not yet signed).
    if (!isUserActivityStatusFrozen && notificationData?.status === KycNotificationStatus.PingReceivedKyc) {
      receiveUserDataModalRemoteState.open();
      Toast.success({ title: t('Ping verification successful') });
      claimRewardsModalRemoteState.open();
    }
  }, [notificationData?.status, isUserActivityStatusFrozen]);

  useEffect(() => {
    setShowEnhanceAssetsNotice(portfolio?.withdrawalThresholdReached);
  }, [portfolio?.withdrawalThresholdReached]);

  useEffect(() => {
    if (isUserActivityStatusActive) {
      queryClient.invalidateQueries(getGetPortfolioQueryKey());
    }
  }, [isUserActivityStatusActive]);

  const handleLTCVerify = (verifyWalletData: VerifyAddressWithSignedFormResult) => {
    setShowLTCForm(true);
    setVerifyLTCWalletData(verifyWalletData);
  };

  if (showLTCForm) {
    return (
      <UserLayout>
        <FormLTC verifyWalletData={verifyLTCWalletData} onDone={() => setShowLTCForm(false)} />
      </UserLayout>
    );
  }

  if (isUserActivityStatusBanned) {
    return (
      <UserLayout>
        {isProfileLoading && <PageSpinner />}
        <KycCardBanned />
      </UserLayout>
    );
  }

  if (isUserActivityStatusWaitingForReview) {
    return (
      <UserLayout>
        {isProfileLoading && <PageSpinner />}
        <KycCardPending />
      </UserLayout>
    );
  }

  if (isUserAtFormAStep) {
    return (
      <UserLayout className={style['compliance']}>
        {isProfileLoading && <PageSpinner />}
        <FormACard />
      </UserLayout>
    );
  }

  if (!isUserActivityStatusActive && FORM_B_STEPS.has(complianceStep)) {
    return (
      <UserLayout className={style['compliance']}>
        {isProfileLoading && <PageSpinner />}
        <FormBCard />
      </UserLayout>
    );
  }

  if (isUserAtNoDataStep || isUserAtEntityTypeDeclarationStep || isUserAtKycRequestStep) {
    return (
      <UserLayout>
        {isProfileLoading && <PageSpinner />}
        <KycCard />
      </UserLayout>
    );
  }

  return (
    <UserLayout>
      <UserLayout.Title>{t('Portfolio')}</UserLayout.Title>

      {canShowEnhanceAssetsNotice && showEnhanceAssetsNotice && (
        <EnhanceAssetsNotice
          handleClickWithdrawFunds={() => {
            setModalType(ModalType.SendReceive);
            setPopupActiveTab(SEND_RECEIVE_TABS.SEND);
          }}
        />
      )}

      <UserLayout.Content className={style['portfolio']}>
        <Row className={style['portfolio__head']}>
          <Col lg={12}>
            <Row className='d-flex align-items-center'>
              <Col lg={8}>
                <div className={style['portfolio__amount']}>
                  <BalanceSummaryModal
                    portfolio={portfolio}
                    selectedCurrency={selectedCurrency}
                    isPortfolioLoading={isPortfolioLoading}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className={style['portfolio__buttons']}>
                  <Button
                    type='primary'
                    design='general'
                    size='large'
                    onClick={() => {
                      setModalType(ModalType.SendReceive);
                      setPopupActiveTab(SEND_RECEIVE_TABS.SEND);
                    }}
                    disabled={!isUserVerified}
                    uppercase
                  >
                    {t('Send / Receive')}
                  </Button>

                  <Link href='/user/portfolio/ramp'>
                    <a className={style['portfolio__ramp']}>{t('Buy / Sell')}</a>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={style['portfolio__assets']}>
          <Col lg={12}>
            <Text heading='4'>Your assets</Text>
            <Table
              data={portfolio?.portfolioItems || []}
              columns={tableColumns}
              isLoading={isPortfolioLoading}
              className={style['portfolio__asset-table']}
              emptyView={<NoAssetView />}
              initialState={{}}
            />
          </Col>
        </Row>
        <Popup
          isOpen={modalType === ModalType.SendReceive}
          title={t('Send or Receive Crypto')}
          className={style['crypto-transaction--modal']}
          contentClassName={style['crypto-transaction--modal-content']}
          onClose={handleSendReceiveClose}
        >
          <CryptoTransaction
            action={popupActiveTab}
            assetId={currentAssetId}
            onCancel={handleSendReceiveClose}
            onLTCVerify={handleLTCVerify}
          />
        </Popup>

        <TransferOnProcessingModal />
        <AddressVerificationFailedModal />
        <AddressVerificationSuccessfulModal />
      </UserLayout.Content>
    </UserLayout>
  );
};

export default Portfolio;
