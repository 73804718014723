import { useGetAllAddressBook } from '@ping/api';
import { t } from '@ping/helpers';
import { ComboField, TextField } from '@ping/uikit';
import { isValidAddress } from '@ping/vendors';
import { useEffect, useReducer, useRef, useState } from 'react';

import AddressSelect from '../AddressSelect';

import style from './style.module.scss';

interface IAddressComboBoxProps {
  assetId?: string;
  isDisabled?: boolean;
  onChange?(address: string): void;
  onSave(address: string): void;
  defaultAddress?: string;
  network?: string;
}

interface IAddressComboBoxState {
  address: string;
  error: string;
  isInvalid: boolean;
}

export const AddressComboBox = (props: IAddressComboBoxProps) => {
  //
  // popover stuff
  const boundaryRef = useRef<HTMLDivElement>();
  const [popoverState, emitPopoverStateChange] = useState({ isOpen: false, placement: 'bottom' });
  //
  //
  const [state, setState] = useReducer(
    (prev: IAddressComboBoxState, next: Partial<IAddressComboBoxState>) => {
      const current = { ...prev, ...next };

      if (current.address === '') {
        current.error = '';
        current.isInvalid = false;
        props.onChange?.('');
      } else if (!isValidAddress({ address: current.address, assetId: props.assetId })) {
        current.error = t('Invalid address');
        current.isInvalid = true;
        props.onChange?.('');
      } else if (current.address !== prev.address) {
        current.error = '';
        current.isInvalid = false;
        props.onChange?.(current.address);
      }

      return current;
    },
    { address: props.defaultAddress || '', error: '', isInvalid: false }
  );

  const contacts = useGetAllAddressBook({
    query: {
      enabled: Boolean(props.network),
      select: contacts => {
        return contacts
          .filter(contact => contact.type.toLowerCase() === props.network.toLowerCase())
          .reduce((map, contact) => map.add(contact.walletAddress), new Set<string>());
      },
    },
  });

  useEffect(() => {
    setState({
      address: '',
    });
  }, [props.network]);

  return (
    <div className={style['address-combo-box']} ref={boundaryRef}>
      <ComboField
        invalid={state.isInvalid}
        isDisabled={props.isDisabled}
        data-popover-panel-open={popoverState.isOpen}
        data-popover-panel-placement={popoverState.placement}
      >
        <TextField
          label={t('Beneficiary address')}
          magnet='end'
          name='address'
          isDisabled={props.isDisabled}
          value={state.address}
          autoComplete='off'
          onChange={address => setState({ address: address.trim() })}
        />

        <ComboField.Divider />

        <AddressSelect
          magnet='start'
          boundaryRef={boundaryRef}
          network={props.network}
          isDisabled={props.isDisabled}
          selectedKey={state.address}
          onPanelPlacementChange={placement => emitPopoverStateChange(s => ({ ...s, placement }))}
          onOpenChange={isOpen => emitPopoverStateChange(s => ({ ...s, isOpen }))}
          onChange={address => setState({ address, error: '', isInvalid: false })}
        />
      </ComboField>

      <p className={style['address-combo-box__error-state']} aria-hidden={!state.error}>
        {state.error}
      </p>

      {!contacts.isLoading && contacts.data?.size > 0 && (
        <p className={style['address-combo-box__empty-state']}>{t('No saved addresses')}</p>
      )}
    </div>
  );
};

export default AddressComboBox;
